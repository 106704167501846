<template>
  <!-- Log
  version 0.2: 20230725
  Update currency views when <1 million
  shows notation with comma, else show in compact
-->
  <v-card-text class="">
    <v-alert
      dense
      dark
      :color="getColor"
    >
      {{ getText }}
    </v-alert>
    <section class="d-flex align-start overflow-x-auto">
      <section>
        <v-card-title class="pt-0 subtitle-2">{{ contentType}}</v-card-title>
        <v-card-subtitle class="pb-0 subtitle-1">{{ itemName }}</v-card-subtitle>
      </section>
      <section class="text-right ml-auto">
        <p class="body-2 mb-0 subtitle-2">Price</p>
        <p class="font-weight-bold subtitle-1 black--text mb-0 text-no-wrap">
          <v-icon small>{{getIcon}}</v-icon>
          <!-- icon need change according to specific ethereum icon-->
         {{ convertETH }}
        </p>
        <p class="subtitle-1 mb-0 text-no-wrap">
          {{ symbol }}
        </p>
      </section>
    </section>
  </v-card-text>
</template>

<script>
// import BigNumber from 'bignumber.js';
import NFTCard from '@/features/nfts/nft-card/NFTCard';
// Add Bignumber;

export default {
  name: 'NFTCardContent',
  mixins: [NFTCard],
  computed: {
    getColor() {
      let color = 'green';
      if (this.onSale) {
        color = 'blue';
      }
      return color;
    },
    getText() {
      let text = 'Currently Not On Sale';
      if (this.onSale) {
        text = 'On Sale';
      }
      return text;
    },
    getIcon() {
      let icon = '';
      if (this.symbol === 'xpd') icon = '$xpdIcon';
      if (this.symbol === 'eth') icon = '$ethereumIcon';
      return icon;
    },
    convertETH() {
      const calculatedVal = this.value;
      const simplifyCostFormatS = new Intl.NumberFormat(undefined, {
        notation: 'standard',
      });
      const simplifyCostFormatC = new Intl.NumberFormat(undefined, {
        notation: 'compact',
        maximumSignificantDigits: 5,
      });
      let outputConversion = simplifyCostFormatC.format(calculatedVal);

      if (calculatedVal < 1000000) { outputConversion = simplifyCostFormatS.format(calculatedVal); }
      return outputConversion.toString();
    },

  },
};
</script>
